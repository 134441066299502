import React, { useState, useEffect } from 'react';
import {
  Button,
} from '@mui/material';
import { getTabsList } from '../../../services/api';
import TableVirtuosoFirst from './TableVirtuosoFirst';
import TableVirtuosoBroadcast from './TableVirtuosoBroadcast';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsersData } from '../../../actions/UsersAcademyActions';

const Tasks = (usergroup) => {
  const [tasksTab, setTasksTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const activeButtonStyle = {
    backgroundColor: '#e9eaeb',
  };

  const dispatch = useDispatch();
  const users = useSelector(state => state.usersAcademyData.usersAcademyData);
  //const loading = useSelector(state => state.usersAcademyData.loading);
  //const error = useSelector(state => state.usersAcademyData.error);

  useEffect(() => {
    dispatch(fetchUsersData());
  }, [dispatch]);

  useEffect(() => {
    const fetchTabs = async () => {
      try {
        const tabs = await getTabsList();
        setTabs(tabs);
      } catch (error) {
        console.error('Failed to fetch teams', error);
      }
    };

    fetchTabs();
  }, []);

  console.log(tabs.length);
  

  return (
    <>
    <div>
    {tabs.length > 0 && (
      tabs.map((tab, index) => (
        tasksTab === index && 
        (tab.name.includes('Broadcast') ? 
          <TableVirtuosoBroadcast 
            users={users} 
            tableName={'Bradcast'} 
            index={5} 
            usergroup={usergroup} 
          /> : 
          <TableVirtuosoFirst 
            users={users} 
            tableName={tab.name} 
            index={index} 
            usergroup={usergroup} 
          />
        )
      ))
    )}
      </div>
      {tabs.map((tab, index) => (
        <Button 
          key={tab.name}
          color="inherit"
          size="medium"
          sx={{ 
            height: '36px', 
            ...(tasksTab === index && activeButtonStyle), 
            ...(!tab.isActive && { display: 'none' }) 
          }}
          onClick={() => setTasksTab(index)}
        >
          {tab.name}
        </Button>
      ))}
    </>
  );
};

export default Tasks;
