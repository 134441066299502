import React, { useState } from 'react';
import {whoisCheck} from '../services/api'

const NCAccounts = () => {
  const [domains, setDomains] = useState('');
  const [results, setResults] = useState([]); 

  const handleDomainChange = (event) => {
    setDomains(event.target.value);
    console.log(event.target.value);
  };

  const checkWhois = async () => {
    const domainsArray = domains.split('\n').filter((domain) => domain.trim() !== '');
    console.log(domainsArray);
    try {
      const response = await whoisCheck(domainsArray);
      console.log(response.data);
      setResults(response.data);
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
    }
  }
  
  return (
    <div>
      <h2>NC Accounts</h2>
      
      {/* Поле для ввода доменов */}
      <textarea
        value={domains}
        onChange={handleDomainChange}
        placeholder="Введите домены через запятую"
        rows="4"
        cols="50"
      />
      <br />
      
      {/* Кнопка для запуска проверки */}
      <button onClick={checkWhois}>Check</button>

      {/* Таблица для отображения результатов */}
      {results.length > 0 && (
        <table border="1">
          <thead>
            <tr>
              <th>Domain</th>
              <th>Registered On</th>
              <th>Expires On</th>
              <th>IP Address</th>
              <th>Nameservers</th>
              <th>Subdomains</th>
              <th>Blocked</th>
              <th>Sold</th>
            </tr>
          </thead>
          <tbody>
            {results.map((item, index) => (
              <tr key={index}>
                <td>{item.domain}</td>
                <td>{item.registered_on}</td>
                <td>{item.expires_on || 'N/A'}</td>
                <td>{item.ip_address}</td>
                <td>{item.nameservers || 'N/A'}</td>
                <td>{item.dns_records.Subdomains || 'N/A'}</td>
                <td>{item.blocked}</td>
                <td>{item.sold}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NCAccounts;
